var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('div',{staticClass:"form-control__select",class:{
      'form-control__select--placeholder': !_vm.element,
      'form-control__select--disabled': _vm.disabled,
      'form-control__select--selected': _vm.element,
    },on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('label',{staticClass:"form-control__select_label",class:{ 'form-control__select_label--selected': _vm.element }},[_vm._v(" "+_vm._s(_vm.placeholder)+" "),(_vm.required)?_c('span',{staticClass:"form-control__select_label_required"},[_vm._v("*")]):_vm._e()]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.element)+" ")]),_c('span',{staticClass:"icon",class:{ 'icon--open': !_vm.disabled ? _vm.visible : false }},[_c('IconArrowTop',{staticClass:"icon-arrow",attrs:{"width":"13","height":"10","color":"#fff"}})],1)]),_c('div',{staticClass:"form-control__items",class:{ 'form-control__items--visible': !_vm.disabled ? _vm.visible : false }},[_c('div',{staticClass:"form-control__items_body"},[_c('ul',{staticClass:"list"},_vm._l((_vm.items),function(i){return _c('li',{key:i.id},[_c('a',{staticClass:"list__item",class:{
              'list__item--active': _vm.element === i.title,
            },on:{"click":function($event){$event.preventDefault();_vm.$emit('input', {
                id: i.id,
                title: i.title,
              });
              _vm.visible = false;}}},[_c('span',{class:`list__item--status_${i.title}`}),_vm._v(_vm._s(i.title))])])}),0)])]),_c('p',{staticClass:"form-control__error"},[_vm._v(_vm._s(_vm.validateError))])])
}
var staticRenderFns = []

export { render, staticRenderFns }